$(window).load(function () {
    setTimeout(function(){$(".site-loader").fadeOut("slow")}, 1000);
});

jQuery(document).ready(function ($) {


    //center vertically overlay menu if there's enough space

    $(window).on('load orientationchange resize', function () {

        //console.log('window ' + $(window).height() + ', menu ' + $('.site-overlay .site-overlay__content .site-overlay__menu ul').outerHeight(true));

        if ($(window).height() > $('.site-overlay .site-overlay__content .site-overlay__menu > ul').outerHeight(true)) 
            $('.site-overlay .site-overlay__content').addClass('verticallyCentered');
        else 
            $('.site-overlay .site-overlay__content').removeClass('verticallyCentered');
    });



    $('.site-header__hamburger').on('click tap', function(e) {
        e.preventDefault();
        $(this).find('.bar').toggleClass('animate');
  
        if($('.site-overlay').hasClass('opened')){
        
          $('.site-overlay__content').css("opacity", 0);
  
          setTimeout(function(){
            $('.site-overlay').css('width','0%');
            $('.site-overlay--dark').css('width','0%');
            $('.site-overlay').removeClass('opened');
          }, 500);  
          
        }else{
          $('.site-overlay--dark').css('width','100%');
         
         setTimeout(function(){
            $('.site-overlay').css('width','100%');
            $('.site-overlay').addClass('opened');
          }, 500);

          setTimeout(function(){
            $('.site-overlay__content').css("opacity", 1);
          }, 1000);

          

        }
      });

      //lightbox
      $(".site-content a[href$='.jpg'],a[href$='.jpeg'],.site-content a[href$='.png'],.site-content a[href$='.gif'],.site-content a[href$='.JPG'],.site-content a[href$='.JPEG']").each(function(){
            $(this).attr('data-lightbox','zoom');
            if($(this).find('img').attr('alt')!='') $(this).attr('data-title',$(this).find('img').attr('alt'));
        });


     // custom select
     $('select.custom-select').each(function(){
        var $this = $(this), numberOfOptions = $(this).children('option').length;
        var selectID=$(this).attr('id');
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');
    
        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
  

        $styledSelect.on('click tap',function(e) {
              e.stopPropagation();
              $('div.select-styled.active').not(this).each(function(){
                  $(this).removeClass('active').next('ul.select-options').hide();
              });
              $(this).toggleClass('active').next('ul.select-options').toggle();
          });
      
          $listItems.on('click tap',function(e) {
              e.stopPropagation();
              $(this).parent('ul.select-options').find('li').removeClass("selected").removeAttr('class');
              $styledSelect.text($(this).text()).removeClass('active');
              $(this).addClass('selected');
              $this.val($(this).attr('rel'));
              $list.hide();
              var valSelected=$this.val();
              //console.log($this.val());

              if(selectID=='sort-blog-posts'){
                  filterList(valSelected);
              }
          });
      
          $(document).on('click tap',function() {
              $styledSelect.removeClass('active');
              $list.hide();
          });
    
    });

    // multiple select
    $('select.js-example-basic-single,select.js-example-basic-multiple').each(function(){
        if($(this).attr('name').search("country")>-1)
            $(this).select2();
        else
            $(this).select2({
                minimumResultsForSearch: Infinity
              });
    });

    $( document ).not('.select2-selection__choice').tooltip({
        position: {
          my: "center bottom-20",
          at: "center top",
          using: function( position, feedback ) {
            $( this ).css( position );
            $( "<div>" )
              .addClass( "arrow" )
              .addClass( feedback.vertical )
              .addClass( feedback.horizontal )
              .appendTo( this );
          }
        }
      });

    $('#searchform').submit(function(e) { 
        var s = $( this ).find("#s").val($.trim($( this ).find("#s").val()));
        if (!s.val()) { 
            e.preventDefault(); 
            $('#s').focus();
        }
    });

    $('#quicksearch').submit(function(e) { 
        var s = $( this ).find("#s2").val($.trim($( this ).find("#s2").val()));
        if (!s.val()) { 
            e.preventDefault(); 
            $('#s2').focus();
        }
    });



    //center content in home header and set it 100% height
    if ($('.site-slider').length > 0) {
        $('.site-slider').css('minHeight', $(window).height());

        $(window).on({
            'orientationchange resize': function () {
                $('.site-slider').css('minHeight', $(window).height());
            }
        });
    }

    if($('.slick-slider').length > 0){
        var hasArrows = true;
        var hasDots = false;

        if($('.slick-slider').hasClass('hasDots')){
            hasArrows = false;
            hasDots = true;
        }


        $('.slick-slider').slick({
            autoplay:false,
            autoplaySpeed: 3000,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            arrows: hasArrows,
            dots:hasDots
          });
    }


    $(window).scroll(function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header .site-top').fadeOut(0);
            $('.site-header').addClass('scrolled');
            $('.site-header__hamburger').addClass('scrolled');

        } else {
            $('.site-header .site-top').fadeIn(500);
            $('.site-header').removeClass('scrolled');
            $('.site-header__hamburger').removeClass('scrolled');

        }

        if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    if ($('img.aligncenter').length > 0) {
        $('img.aligncenter').parent('p').css('textAlign', 'center');
    }

    $("a[href^='#']").not('[href="#"],#closeSearch,#openSearch').on("click touchend", function (e) {
        e.preventDefault();
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 500);
                return false;
            }
        }
    });

    $("a#closeSearch,a#openSearch").on("click touchend", function (e) {
        e.preventDefault();
        $('.site-search').slideToggle( "fast" );
    });


    //load more in blog page
    if ($('.site-content__preview-blog .site-content__article').length > 0) {
            
        $('.site-content__preview-blog .site-content__article.segna').slice(0, 7).removeClass('hidden');
        if($('.site-content__preview-blog .site-content__article.segna').length < 7) $(".site-content__preview-blog a.loadMore").addClass('hidden');

        //filter posts by data-category attribute
        $('select#sort-blog-posts').change(function() {
            var filter = $(this).val()
            filterList(filter);
        });




        $(".site-content__preview-blog a.loadMore").on('click tap', function (e) {
            e.preventDefault();
            $(".site-content__preview-blog .site-content__article.segna:hidden").slice(0, 7).removeClass('hidden').slideDown();

            if ($(".site-content__preview-blog .site-content__article.segna:hidden").length == 0) {
                $(".site-content__preview-blog a.loadMore").addClass('hidden');
            }

        });

            
                
    }


    //direct click on menu items
    if(is_touch_device()){
        $('.site-overlay__menu ul li a').on('tap', function(e) {
            var el = $(this);
            var link = el.attr('href');
            window.location = link;
        });
    }

    if ($('.site-accordion').length > 0) {
        $('.site-accordion').find('.site-accordion__toggle').on('click tap', function () {
            //Expand or collapse this panel
            $(this).next().slideToggle(250);
            //Hide the other panels
            $('.site-accordion__content').not($(this).next()).slideUp(400);
            //Turn the arrow
            $('.site-accordion__toggle').not($(this)).find('.arrow').removeClass('arrow-open');
            $(this).find('.arrow').toggleClass('arrow-open');
        });

    }


});


function is_touch_device() {
    return 'ontouchstart' in window // works on most browsers
        ||
        navigator.maxTouchPoints; // works on IE10/11 and Surface
};

function msieversion() {

    var toReturn = '';
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    var trident = ua.indexOf('Trident/');

    if (msie > 0) {
        toReturn = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    } else if (trident > 0) {
        var rv = ua.indexOf('rv:');
        toReturn = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    } else {
        toReturn = 'other';
    }

    return toReturn;
};

//Blog category filter function
function filterList(value) {
	var list = $(".site-content__preview-blog .site-content__article");
    $(list).addClass('hidden');
    
	if (value == "tutti") {
    

		$(".site-content__preview-blog .site-content__article").each(function (i) {
           if(!$(this).hasClass('segna')) $(this).addClass('segna');
        });
        
        $('.site-content__preview-blog .site-content__article.segna').slice(0, 7).removeClass('hidden');

        if($('.site-content__preview-blog .site-content__article.segna').length < 7) 
            $(".site-content__preview-blog a.loadMore").addClass('hidden');
    
        else
            $(".site-content__preview-blog a.loadMore").removeClass('hidden');
        

	} else {
        

        $(".site-content__preview-blog .site-content__article").each(function (i) {
            $(this).removeClass('segna');
        });
        

		$(".site-content__preview-blog").find("article[data-category*=" + value + "]").each(function (i) {
            $(this).addClass('segna');
        });

        $('.site-content__preview-blog .site-content__article.segna').slice(0, 7).removeClass('hidden');
        
        if($('.site-content__preview-blog .site-content__article.segna').length < 8) 
            $(".site-content__preview-blog a.loadMore").addClass('hidden');
        else
            $(".site-content__preview-blog a.loadMore").removeClass('hidden');

        
        

    }


    
    
}